import axios from 'axios';

let currentController = null;
export default {
  async getSecondEquipmentsWithPagination(params) {
    // Отменяем предыдущий запрос, если он существует
    if (currentController) {
      currentController.abort();
    }
    // Создаём новый AbortController
    currentController = new AbortController();
    const { signal } = currentController;
    const response = await axios.post('/api/SecondEquipments/GetSecondEquipmentsWithPagination/', params, { signal });
    currentController = null;
    return response;
  },
  getSecondEquipmentItem(id) {
    return axios.get('/api/SecondEquipments/GetSecondEquipmentItem', {
      params: {
        id
      }
    });
  },
  addSecondEquipment: (secondEquipment) => axios.post('/api/SecondEquipments', secondEquipment),
  updateSecondEquipment: (secondEquipment) => axios.put(`/api/SecondEquipments/${secondEquipment.id}`, secondEquipment),
  deleteSecondEquipment: (id) => axios.delete(`/api/SecondEquipments/${id}`),
  mergeSecondEquipments(mainId, secondaryIds) {
    return axios.post('/api/SecondEquipments/Merge', { mainId, secondaryIds });
  },
  getSecondEquipmentFactoryName(secondEquipmentId) {
    return axios.get('/api/SecondEquipments/GetSecondEquipmentFactoryName', {
      params: {
        secondEquipmentId
      }
    });
  },
};
